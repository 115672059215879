<template>
  <b-card-code title="微信列表">
    <!-- search input -->

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="addtbcommtity"
    >
      获取产品
    </b-button>
    <b-overlay :show="show" rounded="sm">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="搜索"
              type="text"
              class="d-inline-block"
              @input="handleSearch"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
        }"
        @on-sort-change="onSortChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'mainPic'" class="text-nowrap">
            <b-avatar :src="props.row.mainPic" square class="mx-1" />
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'itemLink'">
            <a :href="props.row.itemLink" target="_blank"> 点击查看</a>
          </span>
          <span v-else-if="props.column.field === 'createTime'">
            {{ timestampToTime(props.row.createTime) }}
          </span>
          <span v-else-if="props.column.field === 'cid'">
            {{ sendtype(props.row.cid) }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> 显示 </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ total }} 条 </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                :value="1"
                :total-rows="total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  VBModal,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,

    BOverlay,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      log: [],
      show: false,
      pageLength: 10,
      total: 0,
      cid: 0,
      desc: "",
      deviceid: "",
      module1: "",
      search: "",
      roleSelect: null,
      roleOptions: [],
      accountSelect: null,
      accountOptions: [],
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "商品标题",
          field: "dtitle",
        },
        {
          label: "原价",
          field: "originalPrice",
        },
        {
          label: "券后价",
          field: "actualPrice",
        },
        {
          label: "商品类别",
          field: "cid",
        },
        {
          label: "主图",
          field: "mainPic",
        },
        {
          label: "产品链接",
          field: "itemLink",
        },
        {
          label: "佣金比例",
          field: "commissionRate",
        },
        {
          label: "店铺名称",
          field: "shopName",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    addtbcommtity() {
      this.$swal({
        title: "确定要重新载入产品库吗?",
        text: "加载时间会很长!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("result", result.isConfirmed);
        if (result.isConfirmed) {
          this.show = true;
          useJwt.deletebygoodsid().then((ress) => {
            if (ress.data.code == 0) {
              console.log("删除成功,开始抓去");
              useJwt.tbcommodityAdd();
              setTimeout(() => {
                this.show = false;
                console.log("加载页面");
                useJwt
                  .tbcommodityQuery({
                    cid: this.cid,
                    page: 1,
                    rows: this.pageLength,
                    desc: this.desc,
                  })
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.total = res.data.data.records;
                      this.rows = res.data.data.rows;
                    }
                  });
              }, 10000);
            }
          });
        }
      });
    },
sendtype(type) {
      let array = type.split(",");
      let typename = "";
      array.forEach(function (item, index) {
        switch (item) {
          // server 登陆结果 ok
          case "1":
            typename = "女装";
            break;
          case "2":
            typename = "母婴";
            break;
          case "3":
            typename = "美妆";
            break;
          case "4":
            typename = "居家日用";
            break;
          case "5":
            typename = "鞋品";
            break;
          case "6":
            typename = "美食";
            break;
          case "7":
            typename = "文娱车品";
            break;
          case "8":
            typename = "数码家电";
            break;
          case "9":
            typename = "男装";
            break;
          case "10":
            typename = "内衣";
            break;
          case "11":
            typename = "箱包";
            break;
          case "12":
            typename = "配饰";
            break;
          case "13":
            typename = "户外运动";
            break;
          case "14":
            typename = "家装家纺";
            break;
        }
      });
      return typename;
    },
    handleSearch(searching) {
      useJwt
        .tbcommodityQuery({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          wechatnick: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .tbcommodityQuery({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          wechatnick: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      useJwt
        .tbcommodityQuery({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      console.log(this.desc);
      useJwt
        .tbcommodityQuery({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
  },
  mounted() {
    useJwt
      .tbcommodityQuery({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        console.log(JSON.stringify(res))
        if (res.data.code == 0) {
          console.log(JSON.stringify(res.data.data.records));
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        }
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
